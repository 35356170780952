import { EApiRoutes } from '../../configs/constants'
import { useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Button, FormControl, FormGroup, Input, InputLabel, Switch, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { AxiosRequestConfig } from 'axios'
import { prepareUrlWithId } from '../../helpers'
import { ISettings } from '../../types'
import { Save } from '@mui/icons-material'
import { generateSitemap } from '../../helpers/sitemap'

// const TransactionsRow = (props: {
//     item: IUserTransaction
//     onTransferSubmit: (transferId: number, data: { paymentDetails: string }) => void
//     navigate: NavigateFunction
// }) => {
//     const { item } = props

//     const [updatedItem, updatedItemUpdate] = useState<Pick<IUserTransaction, 'id' | 'status'>>({
//         id: item.id,
//         status: item.status,
//     });

//     return (
//         <tr>
//             <td>{item.id}</td>
//             <td>{item.user.id}</td>
//             <td>{item.createdAt}</td>
//             <td>{item.updatedAt}</td>
//             <td>{item.amount}</td>
//             <td>{item.balanceBefore}</td>
//             <td>{item.balanceAfter}</td>
//             <td>{item.status}</td>
//             <td>{item.transferType}</td>
//             <td>{JSON.stringify(item.details)}</td>
//             <td>
//                 {
//                     [EWalletTransactionStatuses.pending, EWalletTransactionStatuses.onReview].includes(item.status) &&
//                     <TransfeConfirmPopper transferId={item.id} submitUpdate={props.onTransferSubmit} />
//                     }
//                 {/* <Button
//                     onClick={() => onTransferSubmit(updatedItem)}
//                 ><Save color='success' /></Button> */}
//             </td>
//         </tr>
//     )
// }

export const Settings = () => {
    const [settings, settingsUpdate] = useState<ISettings>(null as any)

    const getSettings = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.config,
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                settingsUpdate(items)
            })
    }

    const updateSettings = () => {
        const { id, ...preparedData } = settings
        const preparedParams: AxiosRequestConfig = {
            method: 'post',
            url: prepareUrlWithId(EApiRoutes.adminUpdateSettings, id),
            data: preparedData
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                settingsUpdate(items)
            })
    }

    if (!settings) getSettings()

    console.log('settings  > ', settings)

    return settings ? (
        <div>
            <Typography variant='h5'>Настройки</Typography>

            <FormGroup className='form-group'>
                <FormControl>
                    <InputLabel id={`externalCurrency-label`}>Внешняя валюта</InputLabel>
                    <Input
                        placeholder='Основная валюта'
                        value={settings.baseExternalCurrency}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            baseExternalCurrency: e.target.value
                        })}
                    />
                </FormControl>
            </FormGroup>

            <Typography variant='h6'>Автосоздание. Сфера. Крипто.</Typography>
            <FormGroup className='form-group'>
                <FormControl
                    style={{ width: '100px', marginTop: '30px' }}>
                    <InputLabel id={`active-label`} style={{ marginTop: '-30px' }}>Активен</InputLabel>
                    <Switch
                        checked={settings.autoCreateConfig?.spheres.crypto.isActive}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        isActive: e.target.checked
                                    }
                                }
                            }  // e.target.checked
                        })}
                    />
                </FormControl>
                <FormControl
                    style={{ width: '150px', marginTop: '30px' }}>
                    <InputLabel id={`autoPublish-label`} style={{ marginTop: '-30px' }}>Автопубликация</InputLabel>
                    <Switch
                        checked={settings.autoCreateConfig?.spheres.crypto.autoPublishToVote}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        autoPublishToVote: e.target.checked
                                    }
                                }
                            }  // e.target.checked
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`cronConfig-label`}>Интервал выполнения</InputLabel>
                    <Input
                        placeholder='Интервал выполнения'
                        value={settings.autoCreateConfig?.spheres.crypto.cronConfig}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        cronConfig: e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`amountToVote-label`}>Сумма для участия</InputLabel>
                    <Input
                        placeholder='Сумма для участия'
                        type='number'
                        value={settings.autoCreateConfig?.spheres.crypto.amountToVote}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        amountToVote: +e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`crypto-externalCurrency-label`}>Внешняя валюта</InputLabel>
                    <Input
                        placeholder='Основная валюта'
                        value={settings.autoCreateConfig?.spheres.crypto.externalCurrency}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        externalCurrency: e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`eventPollTimeZone-label`}>Временная зона</InputLabel>
                    <Input
                        placeholder='Временная зона'
                        value={settings.autoCreateConfig?.spheres.crypto.eventPollTimeZone}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        eventPollTimeZone: e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`rateChangeStep-label`}>Шаг изменения курса</InputLabel>
                    <Input
                        placeholder='Шаг изменения курса'
                        type='number'
                        value={settings.autoCreateConfig?.spheres.crypto.rateChangeStep}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        rateChangeStep: +e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`comissionPercent-label`}>Комиссия</InputLabel>
                    <Input
                        placeholder='Комиссия'
                        type='number'
                        value={settings.autoCreateConfig?.spheres.crypto.comissionPercent}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        comissionPercent: +e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`orgPercent-label`}>Комиссия организатора</InputLabel>
                    <Input
                        placeholder='Комиссия организатора'
                        type='number'
                        value={settings.autoCreateConfig?.spheres.crypto.orgPercent}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        orgPercent: +e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`changeDirection-label`}>Направление курса</InputLabel>
                    <Input
                        placeholder='Направление курса'
                        value={settings.autoCreateConfig?.spheres.crypto.changeDirection}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        changeDirection: e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`baseSrcUrl-label`}>URL источника</InputLabel>
                    <Input
                        placeholder='URL источника'
                        value={settings.autoCreateConfig?.spheres.crypto.baseSrcUrl}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        baseSrcUrl: e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`apiAccessKey-label`}>API key</InputLabel>
                    <Input
                        placeholder='API key'
                        value={settings.autoCreateConfig?.spheres.crypto.apiAccessKey}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        apiAccessKey: e.target.value
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id={`rateCurrencies-label`}>Валюты сравнения</InputLabel>
                    <Input
                        placeholder='Валюты сравнения'
                        value={settings.autoCreateConfig?.spheres.crypto.rateCurrencies.join(',')}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            autoCreateConfig: {
                                ...settings.autoCreateConfig,
                                spheres: {
                                    ...settings.autoCreateConfig?.spheres,
                                    crypto: {
                                        ...settings.autoCreateConfig!.spheres.crypto,
                                        rateCurrencies: e.target.value.split(',')
                                    }
                                }
                            }
                        })}
                    />
                </FormControl>
                <FormControl>
                    <Button variant="contained" color="primary" onClick={generateSitemap}>
                        Получить sitemap
                    </Button>
                </FormControl>
            </FormGroup>

            <Button
                onClick={updateSettings}
            ><Save color='success' /></Button>
        </div >
    ) : <Loader />
    // return <Loader /> */

    // return (<>
    //     Settings
    //     <Loader />
    // </>)
}
