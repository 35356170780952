import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useAuthCtx } from '../ctx/AuthContext';
import { useTheme, useMediaQuery } from '@mui/material';
import texts from '../configs/texts';
import { ERoutes, lang, TG_PLATFORM_CH_URL } from '../configs/constants';

export const Navbar: React.FC = () => {
    const { user, logout } = useAuthCtx();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: texts[lang].main, link: '/' },
        { text: texts[lang].horizonOfEvents, link: TG_PLATFORM_CH_URL },
        { text: texts[lang].aboutClub, link: ERoutes.about },
        // ...(user ? [{ text: 'Admin', link: '/admin' }] : []),
    ];

    return (
        <AppBar position="static" className='header-bar'>
            <Toolbar>
                <Typography variant="h1" sx={{ flexGrow: 1 }} style={{ fontSize: '1.5rem' }}>
                    {texts[lang].clubNameValue}
                </Typography>
                {isMobile ? (
                    <>
                        <IconButton color="inherit" edge="start" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                            <List>
                                {menuItems.map((item, index) => (
                                    <ListItem button component={Link} to={item.link} key={index} onClick={toggleDrawer(false)}>
                                        <ListItemText primary={item.text} />
                                    </ListItem>
                                ))}
                                {user && (
                                    <ListItem button onClick={() => { logout(); setDrawerOpen(false); }}>
                                        <ListItemText primary="Logout" />
                                    </ListItem>
                                )}
                            </List>
                        </Drawer>
                    </>
                ) : (
                    <>
                        {menuItems.map((item, index) => (
                            <Button color="inherit" component={Link} to={item.link} key={index}>
                                {item.text}
                            </Button>
                        ))}
                        {/* {user && (
                            <Button color="inherit" onClick={logout}>
                                Logout
                            </Button>
                        )} */}
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};
