import { BASE_URL, EApiRoutes, ERoutes } from '../configs/constants';
import { requestHelper } from './requestHelper';
import { prepareUrlWithId } from '.';

// Асинхронная функция для получения динамических маршрутов с определенным префиксом
async function getEventPollPaths(): Promise<string[]> {
    try {
        const response: string[] = (await requestHelper.request<true>({
            method: 'get',
            url: EApiRoutes.eventPollPaths,
        })) as any as string[];
        return response.map((item: string) => prepareUrlWithId(ERoutes.eventPoll, item));
    } catch (error) {
        console.error('Ошибка getEventPollPaths:', error);
        return [];
    }
}

const downloadSitemap = (sitemapContent: string) => {
    const blob = new Blob([sitemapContent], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sitemap.xml');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url); // Освобождаем память
};

// Генерация sitemap
export async function generateSitemap() {
    // Статические маршруты из enum ERoutes
    const staticRoutes = Object.values(ERoutes).filter(r => !r.includes('/:'));

    // Получаем динамические маршруты с префиксом 'blog'
    const eventPollPathsRoutes = await getEventPollPaths();

    // Собираем все маршруты
    const allRoutes = [...staticRoutes, ...eventPollPathsRoutes];

    // Создаем XML содержимое для sitemap
    const sitemapContent = allRoutes
        .map((route) => {
            return `<url><loc>${BASE_URL}${route}</loc><changefreq>weekly</changefreq><priority>0.8</priority></url>`;
        })
        .join('');

    const sitemapXML = `<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n${sitemapContent}\n</urlset>`;

    return downloadSitemap(sitemapXML)
    // Записываем в файл sitemap.xml
    // fs.writeFileSync('./public/sitemap.xml', sitemapXML, 'utf8');
    // console.log('Sitemap успешно создан!');


}

// Функция запуска генерации по кнопке (в командной строке или в браузере)
// document.getElementById('generateButton')?.addEventListener('click', async () => {
//     await generateSitemap();
// });
