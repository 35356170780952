import axios, { AxiosRequestConfig } from 'axios'
import { getAuthToken } from '.'
import { INotificationProps } from '../types'
import { BASE_API_PATH, ENotificationType, TOTAL_COUNT_HEADER, lang } from '../configs/constants'
import texts from '../configs/texts'

interface IRequestHelperConfig {
    apiUrl: string
}

const requestHelperConfig: IRequestHelperConfig = {
    apiUrl: process.env.NODE_ENV === 'production'
        ? BASE_API_PATH
        : 'http://localhost:3000/api'
}


class RequestHelper {
    #config: IRequestHelperConfig = requestHelperConfig
    initNotification: (props: INotificationProps) => any = () => ''

    init(params: {
        initNotification: (props: INotificationProps) => any
    }) {
        this.initNotification = params.initNotification
    }

    async request<B extends boolean>(
        params: AxiosRequestConfig,
        options: { isListResponse?: B } = {}
    ): Promise<B extends true ? { count: number, list: object[] } : any> {
        if (getAuthToken()) {
            params.headers = {
                ...(params.headers || {}),
                Authorization: getAuthToken()
            }
        }

        return axios.request({
            ...params,
            baseURL: this.#config.apiUrl,
        })
            .then(res => options.isListResponse
                ? {
                    count: +((res.headers as any).get(TOTAL_COUNT_HEADER)),
                    list: res.data
                }
                : res.data
            )
            .catch(err => {
                // console.log('err > ', err.response.data)
                const errorCode: string = err.response.data.errorCode as any;
                this.initNotification({
                    notificationType: ENotificationType.error,
                    message: `${(texts[lang] as any)[errorCode] as string} ${err.response.data.message ? err.response.data.message : ''}`
                })
            })
    }
}

export const requestHelper: RequestHelper = new RequestHelper()
