// Импорт необходимых библиотек
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'; // Для получения id из URL
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Typography,
    Box,
    Button
} from '@mui/material';
import { styled } from '@mui/system';
import { EApiRoutes, EEventPollStatuses, lang, TG_PLATFORM_CH_URL } from '../../configs/constants';
import { requestHelper } from '../../helpers/requestHelper';
import { IEventPoll } from '../../types';
import { Loader } from '../../components/Loader';
import { prepareDate, prepareUrlWithId } from '../../helpers';
import texts from '../../configs/texts';

// Типизация интерфейса
// interface EntityDetails {
//     name: string;
//     status?: 'pending' | 'onVote' | 'completed';
//     voteAvailableUntilDate: string | Date;
//     wayOfResultConfirmation: string;
//     datetimeOfConfirmation: string | Date;
//     initialBankAmount: number;
//     amountToVote: number;
//     comissionPercent?: number;
//     orgPercent?: number;
//     remarks?: string;
//     additionalInfo?: string;
//     confirmedAt?: string | Date;
//     eventDescription: string;
//     confirmedResultAsNumber?: number;
//     totalBankAmount: number;
//     picture: string;
//     tags?: string;
//     maxVotes?: number;
// }

// Стилизация для адаптивной верстки
const ResponsiveTable = styled(Table)(({ theme }) => ({
    minWidth: 650,
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
    },
}));

export const EventPollPublic: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Получаем id из URL
    const [entity, setEntity] = useState<IEventPoll | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        // Симуляция запроса к API по id
        const fetchData = async () => {
            try {
                const response = await requestHelper.request({
                    method: 'get',
                    url: prepareUrlWithId(EApiRoutes.eventPollById, id!),
                });
                setEntity(response as IEventPoll);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching entity data', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return <Loader />;
    }

    if (!entity) {
        return <Typography>Entity not found</Typography>;
    }

    const schemaData = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: entity.name,
        image: entity.picture,
        description: entity.eventDescription,
        keywords: entity.tags?.join(', ') || ''
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                {entity.name}
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2, alignItems: 'center', flexDirection: 'column' }}>
                <img
                    src={entity.picture}
                    alt={entity.name}
                    title={entity.name}
                    style={{ maxWidth: '20%', height: 'auto', borderRadius: '8px', gap: 0.2 }}
                />
                {entity.status === EEventPollStatuses.onVote ? (
                    <Button
                        color="success"
                        component={Link}
                        to={TG_PLATFORM_CH_URL}
                        style={{ marginTop: '10px', background: 'green', color: 'white', borderRadius: '5px' }}
                    >
                        {texts[lang].takeParticipation}
                    </Button>
                ) : ''}
            </Box>

            <TableContainer component={Paper}>
                <ResponsiveTable>
                    <TableBody>
                        <TableRow>
                            <TableCell>{texts[lang].status}</TableCell>
                            <TableCell>{(texts[lang] as any)[entity.status!]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{texts[lang].voteAvailableUntilDate}</TableCell>
                            <TableCell>{prepareDate(entity.voteAvailableUntilDate)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{texts[lang].datetimeOfConfirmation}</TableCell>
                            <TableCell>{prepareDate(entity.datetimeOfConfirmation)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{texts[lang].wayOfResultConfirmation}</TableCell>
                            <TableCell>{entity.wayOfResultConfirmation}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{texts[lang].amountToVote}</TableCell>
                            <TableCell>{entity.amountToVote}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{texts[lang].totalBankAmount}</TableCell>
                            <TableCell>{entity.totalBankAmount}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{texts[lang].comissionPercent}</TableCell>
                            <TableCell>{entity.comissionPercent}%</TableCell>
                        </TableRow>
                        {entity.orgPercent ? (
                            <TableRow>
                                <TableCell>{texts[lang].orgPercent}</TableCell>
                                <TableCell>{entity.orgPercent}%</TableCell>
                            </TableRow>
                        ) : ''}
                        {entity.remarks && (
                            <TableRow>
                                <TableCell>{texts[lang].remarks}</TableCell>
                                <TableCell>{entity.remarks}</TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell>{texts[lang].eventDescription}</TableCell>
                            <TableCell>{entity.eventDescription}</TableCell>
                        </TableRow>
                        {entity.additionalInfo && (
                            <TableRow>
                                <TableCell>{texts[lang].additionalInfo}</TableCell>
                                <TableCell>{entity.additionalInfo}</TableCell>
                            </TableRow>
                        )}
                        {entity.tags && (
                            <TableRow>
                                <TableCell>{texts[lang].tags}</TableCell>
                                <TableCell>{entity.tags.map(t => `#${t}`).join(' ')}</TableCell>
                            </TableRow>
                        )}
                        {entity.participants ? (
                            <TableRow>
                                <TableCell>{texts[lang].participants}</TableCell>
                                <TableCell>{entity.participants as any as number}{entity.maxVotes ? `/${entity.maxVotes}` : ''}</TableCell>
                            </TableRow>
                        ) : ''}
                        {entity.confirmedResultAsNumber ? (
                            <TableRow>
                                <TableCell>{texts[lang].result}</TableCell>
                                <TableCell>{entity.options[entity.confirmedResultAsNumber]}</TableCell>
                            </TableRow>
                        ) : ''}
                    </TableBody>
                </ResponsiveTable>
            </TableContainer>
            <script type="application/ld+json">
                {JSON.stringify(schemaData)}
            </script>
        </Box>
    );
};
